// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("./../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-project-template-js": () => import("./../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-additions-remodels-and-renovations-js": () => import("./../src/pages/services/additions-remodels-and-renovations.js" /* webpackChunkName: "component---src-pages-services-additions-remodels-and-renovations-js" */),
  "component---src-pages-services-custom-and-luxury-residential-design-js": () => import("./../src/pages/services/custom-and-luxury-residential-design.js" /* webpackChunkName: "component---src-pages-services-custom-and-luxury-residential-design-js" */),
  "component---src-pages-services-other-architectural-services-js": () => import("./../src/pages/services/other-architectural-services.js" /* webpackChunkName: "component---src-pages-services-other-architectural-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

